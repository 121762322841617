
.diagonal-gradient-1 {
  position: absolute;
  top: -24px;
  left: -24px;
  height: 100%;
  color: white;
  clip-path: polygon(0 60%, 100% 30%, 100% 0, 0 0);
}
.diagonal-gradient-2 {
  position: absolute;
  top: -24px;
  left: -24px;
  height: 100%;
  background: linear-gradient(135deg, rgba(0,21,41,.9) 30%, rgba(40,172,141,.9) 100%);
  animation: gradient 12s ease infinite;
  width: 100%;
  background-size: 500% 500%;
  color: white;
  clip-path: polygon(0 60%, 100% 30%, 100% 0, 0 0);
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

@-webkit-keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.diagonal-box {
  position: relative;
  margin-top: -1px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  &:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    transform: skewy(-11deg);
    transform-origin: 50% 0;
    outline: 1px solid transparent;
    backface-visibility: hidden;
  }
}

.first-diagonal:before {
  top: -20vh
}

.bg-one:before {
  background: linear-gradient(45deg, rgba(0,21,41,1) 30%, rgba(40,172,141,1) 100%);
  animation: gradient 15s ease infinite;
  background-size: 500% 500%;
}

.bg-three:before {
  background-color: white;
}

.bg-coaches:before {
  background-color: rgba(0, 21, 41, 1);
}

.bg-image-container {

  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  transform: skewy(-11deg);
  transform-origin: 50% 0;
  overflow: hidden;

  .bg-image {

    position: absolute;
    height: 200%;
    width: 100%;
    background-size: cover;
    transform: skewY(11deg);
    top: -20vh;
    opacity: .3;
  }

  .projector {
    background-image: url("../../resources/projector.jpeg");
  }

  .content-creator {
    background-image: url("../../resources/content-creator.jpeg");
    opacity: .2
  }
}

.bg-four:before {
  background-color: #001529;
}

.bg-five:before {
  background: linear-gradient(45deg, rgba(255,255,255,1) 0%, rgba(40,172,141,1) 70%);
  animation: gradient 15s ease infinite;
  background-size: 500% 500%;
}

@primary-color: #28AC8D;